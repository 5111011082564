import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Leftcol from "../components/leftcol"
import Maincol from "../components/maincol"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Caption from "../components/caption"

export default ({ data }) => (
  <Layout>

    <Leftcol>
      <Img
        className=""
        fluid={data.file.childImageSharp.fluid}
        alt=""
      />
      <Caption text="Peter John Filanc at his eldest daughter's wedding, September, 2000" />
    </Leftcol>
    <Maincol>
      <SEO title="Welcome" />
      <h1 className="font-semibold">Welcome</h1>
      <p>
        The Peter John Filanc Foundation was established on May 10, 2007, in memory of Peter Filanc, the nationally recognized leader in the water and wastewater industries. Throughout the foundation’s tenure, thousands of dollars of grants have been given annually to university students that demonstrate leadership within their majors of construction and engineering. The foundation is no longer accepting direct donations and would like to thank all of those who contributed to support Peter’s greatest legacy: education.
    </p>
      <p>
        Learn more about <Link to="/scholarships/">the current Peter John Filanc Endowed Scholarships</Link>.
    </p>
    </Maincol>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "images/peter-tux.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


